<template>
  <validation-observer
    ref="simpleRules"
  >
    <b-card :title="$t('Firma Bilgileri')">
      <company />
    </b-card>
    <b-card>
      <div
        v-for="(form,key) in investorCreateForm"
        :key="key"
      >

        <questions
          :item-key="key"
          :options-data="form.options"
          :question-title="form.title"
          :question-type="form.type"
        />
      </div>
    </b-card>
    <b-button
      variant="primary"
      @click="saveData"
    >
      {{ $t('Gönder') }}
    </b-button>
  </validation-observer>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import Company from '@/views/App/InvestorProfile/Add/Company.vue'
import Questions from '@/views/App/InvestorProfile/Add/Questions.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BButton,
    Company,
    Questions,
    ValidationObserver,
  },
  computed: {
    investorCreateForm() {
      return this.$store.getters['investorProfile/getInvestorProfileForm']
    },
    investorProfileResult() {
      return this.$store.getters['investorProfile/getInvestorProfileResult']
    },
    investorProfileSaveStatus() {
      return this.$store.getters['investorProfile/getInvestorProfileSaveStatus']
    },
  },
  watch: {
    investorProfileSaveStatus(val) {
      if (val.status) {
        this.$router.push('/yatirimci-profili')
      }
    },
  },
  created() {
    localize(this.$i18n.locale)
    this.getInvestorForm()
  },
  methods: {
    getInvestorForm() {
      this.$store.dispatch('investorProfile/investorProfileCreate')
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('investorProfile/investorProfileSave', this.investorProfileResult)
        }
      })
    },
  },
}
</script>
<style scoped></style>
